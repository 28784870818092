<template>
    <div class="content">
        <div class="banner-image">
            <img src="@/assets/images/AboutUs_bg.png" alt="" width="100%">
            <div class="text pre-wrap">{{ $t('trustedDesc') }}</div>
        </div>
        <CommonTitle class="common_title" :text="$t('home.ourCoreValues')" />
        <div class="core_content">
            <div class="row">
                <div class="item mgR16">
                    <img class="bg_image" src="@/assets/images/value1.png" alt="" width="100%">
                    <div class="left">
                        <img src="@/assets/images/Integrity@3x.png" alt="" width="64px" height="64px">
                    </div>
                    <div class="right">
                        <div class="text1">{{ $t('home.integrity') }}</div>
                        <div class="text2">{{ $t('text12') }}</div>
                    </div>
                </div>
                <div class="item">
                    <img class="bg_image" src="@/assets/images/value2.png" alt="" width="110%">
                    <div class="left">
                        <img src="@/assets/images/Compassion@3x.png" alt="" width="64px" height="64px">
                    </div>
                    <div class="right">
                        <div class="text1">{{ $t('home.compassion') }}</div>
                        <div class="text2">{{ $t('text13') }}</div>
                    </div>
                </div>
            </div>
            <div class="row mgT16">
                <div class="item">
                    <img class="bg_image" src="@/assets/images/value3.png" alt="" width="100%">
                    <div class="left">
                        <img src="@/assets/images/Accountability@3x.png" alt="" width="64px" height="64px">
                    </div>
                    <div class="right">
                        <div class="text1">{{ $t('home.accountability') }}</div>
                        <div class="text2">{{ $t('text14') }}</div>
                    </div>
                </div>
                <div class="item">
                    <img class="bg_image" src="@/assets/images/value4.png" alt="" width="110%">
                    <div class="left">
                        <img src="@/assets/images/CustomerCommitment@3x.png" alt="" width="64px" height="64px">
                    </div>
                    <div class="right">
                        <div class="text1">{{ $t('home.customerCommitment') }}</div>
                        <div class="text2">{{ $t('text15') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <CommonTitle class="common_title2" :text="$t('home.contactUs')" />
        <div class="map_content">
            <div class="flex-space">
                <div class="map">
                    <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3965.800393833846!2d106.7781514!3d-6.2899462!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1ce19110f67%3A0x6b3aabc557a3437!2sJl.%20R.A.%20Kartini%20No.1%2C%20RT.9%2FRW.7%2C%20Lb.%20Bulus%2C%20Kec.%20Cilandak%2C%20Kota%20Jakarta%20Selatan%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2012440%2C%20Indonesia!5e0!3m2!1sen!2sjp!4v1698824997504!5m2!1sen!2sjp"
                    width="568"
                    height="260"
                    style="border:0; border-radius: 16px"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="info">
                    <p class="title">{{ $t('home.Mandiri') }}</p>
                    <div class="detail">
                        <img src="@/assets/images/location@2x.png" width="16px" style="vertical-align: top">
                        <span class="pre-wrap">{{ $t('home.squareMall') + '\n' +$t('home.address') }}</span>
                    </div>
                    <div class="detail">
                        <img src="@/assets/images/phone@2x.png" width="16px">
                        <span>+62 813 9809 9559</span>
                    </div>
                    <div class="detail">
                        <img src="@/assets/images/email@2x.png" width="16px" style="cursor: pointer;">
                        <a :href="'mailto:info@indosumbermandiri.com'">
                            <span class="text text-link" style="display:inline-block; cursor: pointer; width: 280px">
                                info@indosumbermandiri.com
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">{{ $t('home.footerAddress') }}</div>
        <div style="height: 32px"></div>
    </div>
</template>

<script>
    import { Vue, Component } from 'vue-property-decorator'
    import CommonTitle from './component/CommonTitle.vue'

    @Component({
        name: 'AboutUs',
        components: {
            CommonTitle
        }
    })
    export default class extends Vue {

    }
</script>

<style lang="scss" scoped>
.content {
    margin-top: 50px;
    ::v-deep .common_title {
        margin-top: 60px !important;
    }
    ::v-deep .common_title2 {
        margin-top: 60px !important;
    }
    .banner-image {
        position: relative;
        .text {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            white-space: break-word;
            text-align: center;
            width: 1160px;
            font-weight: 400;
            font-size: 20px;
            color: #222222;
            line-height: 27px;
        }
    }
    .core_content {
        margin: 0 140px;
        margin-top: 42px;
        .row {
            display: flex;
            justify-content: space-between;
        }
        .item {
            position: relative;
            width: 50%;
            padding: 32px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            margin-right: 16px;
            .bg_image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                z-index: -1;
            }
            .right {
                margin-left: 26px;
                .text1 {
                    line-height: 64px;
                    font-size: 20px;
                    font-weight: 800;
                    color: #01241E;
                }
                .text2 {
                    font-size: 15px;
                    color: #777777;
                    width: 85%;
                    line-height: 24px;
                }
            }
        }
    }
    .map_content{
        width: 1160px;
        margin: 40px auto 80px auto;
        .map{

        }
        .info{
            .title{
                font-size: 18px;
                font-weight: 900;
                color: #01241E;
                line-height: 21px;
                letter-spacing: 1px;
            }
            .detail{
                margin: 24px 0;
                img{
                    vertical-align: middle;
                    margin-right: 18px;
                }
                span{
                    width: 520px;
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 400;
                    color: #01241E;
                    line-height: 19px;
                    letter-spacing: 1px;
                }
            }
        }
        .fb{
            cursor: pointer;
            line-height: 36px;
            width: 130px;
            height: 36px;
            background: #FFFFFF;
            border-radius: 18px;
            border: 1px solid #E7E7E7;
            font-size: 16px;
            color: #222222;
            letter-spacing: 1px;
            img{
                vertical-align: middle;
                margin: 0 10px;
            }
            span{
                vertical-align: middle;
            }
        }
    }
    .footer {
        text-align: center;
        margin-top: 79px;
        font-size: 16px;
        color: #777;
    }
}
</style>
